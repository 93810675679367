import React, { useState } from "react"
import PropTypes from "prop-types"

import CheckboxList from "../../form_items/CheckboxList"

import {
  Button,
} from 'carbon-components-react';

import {
  ArrowDown32,
  ArrowUp32,
} from '@carbon/icons-react'

const defaultState = {
  collapsed: true,
}

const DealTransactionFiltersCheckbox = ({ items, selectedItems, onChange, label, name, buttonLabel, displayedItemsAmount = 3, disabled = false }) => {
  const [state, setState] = useState(defaultState)

  const handleToggleCollapse = () => {
    setState({ ...state, collapsed: !state.collapsed })
  }

  const displayedItems = state.collapsed ? items.slice(0, displayedItemsAmount) : items

  return(
    <React.Fragment>
      <CheckboxList
        items={displayedItems}
        selectedItems={selectedItems}
        onChange={onChange}
        clearable={true}
        label={label}
        name={name}
        className="fi--mt--24"
        disabled={disabled}
      />
      { (items.length > displayedItemsAmount) &&
        <Button
          className="fi--pl--0"
          kind="ghost"
          size="field"
          renderIcon={state.collapsed ? ArrowDown32 : ArrowUp32}
          onClick={handleToggleCollapse}
          >
          {state.collapsed ? `Show all ${items.length} ${buttonLabel}` : `Show less ${buttonLabel}`}
        </Button>
      }
    </React.Fragment>
  )
}

DealTransactionFiltersCheckbox.propTypes = {
  items: PropTypes.array,
  selectedItems: PropTypes.array,
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

export default DealTransactionFiltersCheckbox
